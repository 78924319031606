<script setup lang="ts">
const currentYear = new Date().getFullYear();
</script>

<template>
  <footer class="bg-indigo-700 dark:bg-indigo-900 text-white py-4">
    <div class="container mx-auto px-4">
      <div class="flex flex-col md:flex-row items-center justify-between">
        <p class="text-sm">&copy; {{ currentYear }} StudyPlnr. All rights reserved.</p>
        <NuxtLink to="/privacy-policy" class="text-sm underline hover:text-indigo-200">Privacy Policy</NuxtLink>
      </div>
    </div>
  </footer>
</template>
