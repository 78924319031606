<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { ref, watch } from "vue";
import type { User } from "../server/types/types";

const emit = defineEmits(["close"]);

defineProps<{
  studyTechniques: {
    label: string;
    value: string;
  }[];
}>();

const userString = useStorage<string>("user", null);
const user = ref<User | null>(null);

watch(
  userString,
  (newValue) => {
    if (newValue) {
      user.value = JSON.parse(newValue);
    } else {
      user.value = null;
    }
  },
  { immediate: true }
);

const preferredStudyTechnique = ref(user.value?.preferredStudyTechnique || "");
const theme = ref(user.value?.theme || "light");

const updateUserSettings = () => {
  if (user.value) {
    user.value.preferredStudyTechnique = preferredStudyTechnique.value;
    user.value.theme = theme.value;
    userString.value = JSON.stringify(user.value);
    emit("close");
  }
};
</script>

<template>
  <div class="space-y-6 dark:text-white">
    <FormField v-slot="{ componentField }" name="preferredStudyTechnique">
      <FormItem>
        <FormLabel>Preferred Study Technique</FormLabel>
        <Select v-model="preferredStudyTechnique" v-bind="componentField">
          <FormControl>
            <SelectTrigger>
              <SelectValue placeholder="Select a preferred study technique" />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            <SelectItem v-for="technique in studyTechniques" :key="technique.value" :value="technique.value">
              {{ technique.label }}
            </SelectItem>
          </SelectContent>
        </Select>
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="theme">
      <FormItem>
        <FormLabel>Theme</FormLabel>
        <Select v-model="theme" v-bind="componentField">
          <FormControl>
            <SelectTrigger>
              <SelectValue placeholder="Select a theme" />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            <SelectItem value="light">Light</SelectItem>
            <SelectItem value="dark">Dark</SelectItem>
          </SelectContent>
        </Select>
      </FormItem>
    </FormField>

    <div class="flex justify-end">
      <Button @click="updateUserSettings">Save Settings</Button>
    </div>
  </div>
</template>
