<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { computed } from "vue";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "zod";
import type { User } from "../server/types/types";

const emit = defineEmits(["close"]);

const userString = useStorage<string>("user", null);
const user = computed(() => {
  if (userString.value) {
    return JSON.parse(userString.value) as User;
  }
  return null;
});

const studyTimeOptions = [
  { label: "Morning", value: "morning" },
  { label: "Afternoon", value: "afternoon" },
  { label: "Evening", value: "evening" },
  { label: "Night", value: "night" },
];

const formSchema = toTypedSchema(
  z.object({
    name: z.string().min(2, "Name must be at least 2 characters"),
    studyGoal: z.string().min(5, "Study goal must be at least 5 characters"),
    preferredStudyTime: z.enum(["morning", "afternoon", "evening", "night"]),
  })
);

const { handleSubmit, setFieldValue, values } = useForm({
  validationSchema: formSchema,
  initialValues: {
    name: user.value?.name || "",
    studyGoal: user.value?.studyGoal || "",
    preferredStudyTime: user.value?.preferredStudyTime || "morning",
  },
});

const updateProfile = handleSubmit((formValues) => {
  if (user.value) {
    Object.assign(user.value, formValues);
    userString.value = JSON.stringify(user.value);
    emit("close");
  }
});
</script>

<template>
  <div class="space-y-6 dark:text-white" v-if="user">
    <form @submit="updateProfile" class="space-y-6">
      <FormField v-slot="{ componentField }" name="name">
        <FormItem>
          <FormLabel>Name</FormLabel>
          <FormControl>
            <Input v-model="values.name" v-bind="componentField" />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>

      <FormField v-slot="{ componentField }" name="studyGoal">
        <FormItem>
          <FormLabel>Study Goal</FormLabel>
          <FormControl>
            <Textarea
              v-model="values.studyGoal"
              v-bind="componentField"
              placeholder="e.g., Pass my final exams, Learn a new language"
              class="resize-none"
            />
          </FormControl>
          <FormDescription>This will help us tailor your study plans.</FormDescription>
          <FormMessage />
        </FormItem>
      </FormField>

      <FormField v-slot="{ componentField }" name="preferredStudyTime">
        <FormItem>
          <FormLabel>When do you prefer to study?</FormLabel>
          <Select v-model="values.preferredStudyTime" v-bind="componentField">
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="Select your preferred study time" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <SelectItem v-for="option in studyTimeOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </SelectItem>
            </SelectContent>
          </Select>
          <FormDescription>We'll optimize your study schedule based on this.</FormDescription>
          <FormMessage />
        </FormItem>
      </FormField>

      <div class="flex justify-end">
        <Button type="submit">Update Profile</Button>
      </div>
    </form>
  </div>
</template>
