<script setup lang="ts">
import { ref } from "vue";
import { User, Settings } from "lucide-vue-next";

const showProfile = ref(false);
const showSettings = ref(false);

const studyTechniques = [
  { label: "Pomodoro Technique", value: "pomodoro" },
  { label: "Spaced Repetition", value: "spaced_repetition" },
  { label: "Active Recall", value: "active_recall" },
  { label: "Feynman Technique", value: "feynman" },
  { label: "Mind Mapping", value: "mind_mapping" },
  { label: "SQ3R Method", value: "sq3r" },
];
</script>

<template>
  <header class="bg-indigo-700 dark:bg-indigo-900 text-white py-4">
    <div class="container mx-auto px-4 flex justify-between items-center">
      <h1 class="text-2xl font-bold">
        <img src="/img/studyplnr.png" alt="StudyPlnr Logo" class="h-6 md:h-8 lg:h-12 w-auto brightness-[6]" />
      </h1>
      <div class="flex space-x-4">
        <Button variant="ghost" @click="showProfile = true">
          <User class="h-5 w-5" />
        </Button>
        <Button variant="ghost" @click="showSettings = true">
          <Settings class="h-5 w-5" />
        </Button>
      </div>
    </div>

    <Dialog v-model:open="showProfile">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>User Profile</DialogTitle>
          <DialogDescription> Update your profile information here. </DialogDescription>
        </DialogHeader>
        <UserProfile @close="showProfile = false" />
      </DialogContent>
    </Dialog>

    <Dialog v-model:open="showSettings">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>User Settings</DialogTitle>
          <DialogDescription> Customize your study preferences and app settings. </DialogDescription>
        </DialogHeader>
        <UserSettings :study-techniques="studyTechniques" @close="showSettings = false" />
      </DialogContent>
    </Dialog>
  </header>
</template>
